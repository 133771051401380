import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../photos/logo.png';
import { ImHome } from 'react-icons/im';
import { FaWpexplorer } from 'react-icons/fa';
import { FaHeartCirclePlus } from 'react-icons/fa6';
import axios from 'axios';
import './Navbar.css'; // Import the CSS file

const Navbar = () => {
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [username, setUsername] = useState("");

  useEffect(() => {
    const fetchLoginStatus = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_backendURL}/login`,
          { withCredentials: true }
        );
        if (response.data.loggedIn) {
          setLoggedIn(true);
          setUsername(response.data.user.name); // Assuming user object has name property
        }
      } catch (err) {
        console.error("Error fetching login status:", err);
      }
      setLoading(false);
    };

    fetchLoginStatus();
  }, []);

  const handleLogout = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_backendURL}/logout`, {}, { withCredentials: true });
      setLoggedIn(false);
      setUsername("");
    } catch (err) {
      console.error("Logout error:", err);
    }
  };

  return (
    <div className='navbar'>
      {/* Left Side */}
      <div>
        <img src={logo} alt='Logo' className='logo' />
      </div>

      {/* Center Menu */}
      <div className='menu'>
        <ul className='flex items-center px-0'>
          <li className='mr-4'>
            <Link to='/home'>
              <ImHome className='w-8 h-8' />
            </Link>
          </li>
          <li className='mr-4'>
            <Link to='/explore'>
              <FaWpexplorer className='w-8 h-8'/>
            </Link>
          </li>
          <li>
            <Link to='/favourites'>
              <FaHeartCirclePlus className='w-8 h-8' />
            </Link>
          </li>
        </ul>
      </div>

      {/* Right Side */}
      <div className='user-section'>
        {loading ? (
          <div>Loading...</div>
        ) : loggedIn ? (
          <div className='user-info'>
            <span className='username'>{username}</span>
            <Link to='/home' onClick={handleLogout} className='logout-btn ml-2'>
              Logout
            </Link>
          </div>
        ) : (
          <div className='login-link text-white ml-2'>
            <Link to='/login'>
              Log in
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
