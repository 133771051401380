


export default function Login() {
    return (
        <div>
            
            <h1 className='text-white'>
                Error 404: Not Found!!
            </h1>
        </div>
    )
}