import Navbar from "../components/Navbar.js";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Login = () => {
    const [values, setValues] = useState({
      email: "",
      password: "",
    });
    const [errors, setErrors] = useState({}); // Define errors state
    const [loggedIn, setLoggedIn] = useState(false);
    const navigate = useNavigate();
  
    const handleInput = (event) => {
      setValues({ ...values, [event.target.name]: event.target.value });
    };
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_backendURL}/login`,
          values,
          { withCredentials: true }
        );
        if (res.data.Login) {
          setLoggedIn(true);
          navigate("/home");
        } else {
          alert("Wrong username/password");
        }
      } catch (err) {
        console.error("Login error:", err);
        alert("An error occurred while logging in");
      }
    };
  
    useEffect(() => {
      const checkLoginStatus = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_backendURL}/login`,
            { withCredentials: true }
          );
          if (response.data.loggedIn) {
            setLoggedIn(true);
          }
        } catch (err) {
          console.error("Login status error:", err);
        }
      };
      checkLoginStatus();
    }, []);
  
    return (
        <div className="d-flex flex-column bg-white vh-100">
            <Navbar />
            <div className="d-flex justify-content-center align-items-center bg-black vh-100">
                <div className="bg-white p-3 rounded w-25">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="email">Email</label>
                            <input type="email" className="form-control rounded-0"
                                onChange={handleInput} placeholder="Enter Email" name='email' />
                            {errors.email && <span className="text-danger"> {errors.email}</span>}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password">Password</label>
                            <input type="password" className="form-control rounded-0"
                                onChange={handleInput} placeholder="Enter Password" name='password' />
                            {errors.password && <span className="text-danger"> {errors.password}</span>}
                        </div>
                        <button type='submit' className="btn btn-success w-100 rounded-0"><strong>Log In</strong></button>
                        <p>Agreed to terms and policies</p>
                        <Link to='/signup' className="btn btn-default border w-100 bg-light rounded-0 text-decoration-none">Create Account</Link>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Login;
